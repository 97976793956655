import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function History() {
    const [history, setHistory] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const storedHistory = JSON.parse(localStorage.getItem('history')) || [];
        setHistory(storedHistory);
    }, []);

    const navigateToMainScreen = () => {
        navigate('/');  // Navigate back to the MainScreen
    };

    return (
        <div className="container mt-5 pt-5">
            <button className="btn btn-primary mb-4" onClick={navigateToMainScreen}>Back to Main Screen</button>
            <h2>History</h2>
            <ul className="list-group">
                {history.map((entry, index) => (
                    <li key={index} className="list-group-item">
                        <p><strong>Date:</strong> {new Date(entry.date).toLocaleString()}</p>
                        <p>{entry.script}</p>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default History;
