import React, { useState, useEffect } from 'react';
import supabase from './SupaBaseClient';
import ScriptHandler from './ScriptHandler';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/MainScreen.css';
import { Spinner, Dropdown } from 'react-bootstrap';
import {useNavigate} from 'react-router-dom';

function MainScreen() {
    const [accomplishments, setAccomplishments] = useState([]);
    const [blockers, setBlockers] = useState([]);
    const [newEntry, setNewEntry] = useState('');
    const [script, setScript] = useState('');
    const [displayedScript, setDisplayedScript] = useState('');
    const [showScript, setShowScript] = useState(false);
    const [userToken, setUserToken] = useState(null);
    const [loading, setLoading] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [isMobile, setIsMobile] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const checkAuthState = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                console.log('User authenticated:', session.user);
                setUserToken(session.access_token);
            } else {
                console.log('User not authenticated');
                navigate('/login');
            }
        };

        checkAuthState().then(r => console.log(r));

        const storedAccomplishments = JSON.parse(localStorage.getItem('accomplishments')) || [];
        const storedBlockers = JSON.parse(localStorage.getItem('blockers')) || [];
        setAccomplishments(storedAccomplishments);
        setBlockers(storedBlockers);

        const handleBeforeInstallPrompt = (e) => {
            e.preventDefault();
            setDeferredPrompt(e);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        // Check if the user is on a mobile device
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;
        if (/android/i.test(userAgent) || (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream)) {
            setIsMobile(true);
        }

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, [navigate]);

    const handleAddAccomplishment = () => {
        if (newEntry.trim() !== '') {
            const updatedAccomplishments = [...accomplishments, newEntry];
            setAccomplishments(updatedAccomplishments);
            localStorage.setItem('accomplishments', JSON.stringify(updatedAccomplishments));
            setNewEntry('');
        }
    };

    const handleAddBlocker = () => {
        if (newEntry.trim() !== '') {
            const updatedBlockers = [...blockers, newEntry];
            setBlockers(updatedBlockers);
            localStorage.setItem('blockers', JSON.stringify(updatedBlockers));
            setNewEntry('');
        }
    };

    const handleDeleteAccomplishment = (index) => {
        const updatedAccomplishments = accomplishments.filter((_, i) => i !== index);
        setAccomplishments(updatedAccomplishments);
        localStorage.setItem('accomplishments', JSON.stringify(updatedAccomplishments));
    };

    const handleDeleteBlocker = (index) => {
        const updatedBlockers = blockers.filter((_, i) => i !== index);
        setBlockers(updatedBlockers);
        localStorage.setItem('blockers', JSON.stringify(updatedBlockers));
    };

    const handleGenerateScript = async () => {
        if (!userToken) {
            alert('You must be logged in to generate a script.');
            return;
        }
        if (loading) {
            return;
        }
        setLoading(true);
        const cloudFunctionUrl = 'https://standup-workers-ai.abdulbaaridavids04.workers.dev/';
        const scriptHandler = new ScriptHandler(cloudFunctionUrl);
        try {
            const generatedScript = await scriptHandler.generateScript(accomplishments, blockers, userToken);
            console.log('Script:', generatedScript);
            setScript(generatedScript);
            setShowScript(true);
            setDisplayedScript('');
            streamScript(' ' + generatedScript);

            const history = JSON.parse(localStorage.getItem('history')) || [];
            history.push({
                script: generatedScript,
                date: new Date().toISOString(),
            });
            localStorage.setItem('history', JSON.stringify(history));
        } catch (error) {
            if (error.message === 'Generation limit reached for today') {
                alert('You have reached your generation limit for today. 3/3 allowed.')
            } else {
                console.error('Error generating script:', error);
                console.log(script)
            }
        } finally {
            setLoading(false);
        }
    };

    const streamScript = (fullScript) => {
        let index = 0;
        const intervalId = setInterval(() => {
            if (index < (fullScript.length - 1)) {
                setDisplayedScript((prev) => prev + fullScript[index]);
                index++;
            } else {
                clearInterval(intervalId);
            }
        }, 20);
    };

    const handleCloseScript = () => {
        setShowScript(false);
    };

    const navigateToHistory = () => {
        navigate('/history');
    };

    const handleLogout = async () => {
        await supabase.auth.signOut();
        navigate('/login');
    };

    const handleAddToHomeScreen = () => {
        if (deferredPrompt) {
            deferredPrompt.prompt();
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                setDeferredPrompt(null);
            });
        } else {
            alert("Android: Click the 'Add to Homescreen' button in the three-dot menu. \n" +
                "iOS: Click the 'Add to Homescreen' button in the share menu.");
        }
    };

    return (
        <>
            <nav className="navbar navbar-light fixed-top">
                <div className="container-fluid justify-content-between">
                    <span className="navbar-brand mb-0 h1">Ugh Standup</span>
                    <Dropdown>
                        <Dropdown.Toggle variant="secondary" className="btn">
                            Menu
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={navigateToHistory}>View History 📜</Dropdown.Item>
                            {isMobile && <Dropdown.Item onClick={handleAddToHomeScreen}>Add to Homescreen 📲</Dropdown.Item>}
                            <Dropdown.Item onClick={handleLogout}>Logout </Dropdown.Item>
                            <Dropdown.Item onClick={() => window.open('https://abduldavids.co.za/posts/ughstandup/', '_blank')}>About</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item onClick={() => window.open('https://abduldavids.co.za', '_blank')}>© 2024 Abdul Davids</Dropdown.Item>

                        </Dropdown.Menu>


                    </Dropdown>
                </div>
            </nav>
            <div className="container mt-5 pt-5">
                <div className="card mb-4 p-3">
                    <div className="card-body">
                        <div className="input-group">
                            <input
                                type="text"
                                className="form-control"
                                value={newEntry}
                                onChange={e => setNewEntry(e.target.value)}
                                placeholder="Any wins or blockers?"
                            />
                        </div>
                        <div className="d-flex justify-content-between">
                            <button className="btn btn-primary" onClick={handleAddAccomplishment}>🥇 Add Win </button>
                            <button className="btn btn-secondary" onClick={handleAddBlocker}>⛔ Add Blocker </button>
                        </div>
                    </div>
                </div>
                <button
                    className="btn btn-success w-100 mb-4"
                    onClick={handleGenerateScript}
                    disabled={loading}
                >
                    {loading ? <Spinner animation="border" size="sm" /> : 'Generate 🚀'}
                </button>
                <div className="row">
                    <div className="col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h2>Wins</h2>
                                {accomplishments.length === 0 && (
                                    <p className="noItem" >Add some wins!</p>
                                )}
                                <ul className="list-group">
                                    {accomplishments.map((item, index) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                            {item}
                                            <button className="btn btn-sm" onClick={() => handleDeleteAccomplishment(index)}>❌</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 mb-4">
                        <div className="card h-100">
                            <div className="card-body">
                                <h2>Blockers</h2>
                                {blockers.length === 0 && (
                                    <p className={"noItem"} >What's blocking you today?</p>
                                )}
                                <ul className="list-group">
                                    {blockers.map((item, index) => (
                                        <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                                            {item}
                                            <button className="btn btn-sm" onClick={() => handleDeleteBlocker(index)}>❌</button>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {showScript && (
                <div className={`generated-script ${showScript ? 'show' : ''}`}>
                    <button className="close-btn" onClick={handleCloseScript}>×</button>
                    <h2>Say this</h2>
                    <p>{displayedScript}</p>
                </div>
            )}
        </>
    );
}

export default MainScreen;
