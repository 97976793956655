import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './SupaBaseClient'; // Adjust the path to where you initialize the Supabase client
import './styles/Login.css';

function Login() {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const navigate = useNavigate();

    useEffect(() => {
        const checkUserSession = async () => {
            const { data: { session } } = await supabase.auth.getSession();
            if (session) {
                navigate('/'); // Redirect to main screen if already logged in
            }
        };

        checkUserSession();
    }, [navigate]);

    const googleSignIn = async () => {
        setLoading(true);
        try {
            const { error } = await supabase.auth.signInWithOAuth({
                provider: 'google',
            });
            if (error) throw error;

            console.log('Signed in with Google');
        } catch (error) {
            console.error('Error signing in with Google:', error.message);
            setLoading(false);
        }
    };

    const emailSignIn = async (e) => {
        e.preventDefault();
        setLoading(true);
        setMessage('');
        try {
            const { error } = await supabase.auth.signInWithOtp({
                email: email,
                options: {
                    shouldCreateUser: true, // Set to false if you don't want automatic sign-up
                    emailRedirectTo: 'https://standup.abduldavids.co.za/', // Change this to your redirect URL
                },
            });
            if (error) throw error;
            setMessage('Check your email for a sign in link!');
        } catch (error) {
            console.error('Error signing in with email:', error.message);
            setMessage('Error sending magic link. Please try again.');
            setLoading(false);
        }
    };

    return (
        <div>
            <div className="login-container">
                <div className="login-card">
                    <h1>Ugh Standup</h1>
                    <p>Another aspect of your job replaced 🤙</p>


                    <form onSubmit={emailSignIn} className="form-container">
                        <input
                            type="email"
                            className="input-field"
                            placeholder="Enter your email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <button type="submit" className="email-signin-button" disabled={loading}>
                            {loading ? <div className="spinner"></div> : 'Send'}
                        </button>
                    </form>
                    {message && <p className="message">{message}</p>}
                    <div className="divider">or</div>
                    <button
                        onClick={googleSignIn}
                        className="google-signin-button"
                        disabled={loading}
                    >
                        {loading ? <div className="spinner"></div> : (
                            <>
                                <img
                                    src="https://upload.wikimedia.org/wikipedia/commons/c/c1/Google_%22G%22_logo.svg"
                                    alt=""
                                    className="google-logo"
                                />
                                Sign in with Google
                            </>
                        )}
                    </button>
                    <p className="small-note">
                       <a href="https://abduldavids.co.za/posts/ughstandup/#why-do-i-need-to-sign-inhttps://abduldavids.co.za" target="_blank" rel="noopener noreferrer">Why do I need to sign in</a>
                    </p>
                </div>
            </div>
            <footer className="login-footer">
                <p>Made with 🍵 by <a href="https://abduldavids.co.za" target="_blank" rel="noopener noreferrer">Abdul
                    Davids</a></p>
            </footer>
        </div>
    );
}

export default Login;
